import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'

import Paleta from '../../static/img/paletaRAL.jpg'

import "../components/layout.css"

export const ProduktyPageCopy3Template = ({ image, intro, gallery, heading }) => (
         <div>
           <div>
             <div></div>
    </div> <h3 className="has-text-weight-semibold is-size-2 headingCSS">
      {intro.heading}
    </h3>
    <section className="section section--gradient mojasekcja">
             <div className="container">
               <div className="section produktyP">
                 <div className="columns">
                   <div className="column is-10 is-offset-1">
                     <div className="content">
                       <div className="columns">
                  <div className="column is-12">
                  </div>
                       </div>
                       <div className="elooo">
                         <Features
                           gridItems={intro.blurbs}
                           key={intro.toString()}
                           href=""
                         />
                       </div>
                       <div className="galeriaRIGHT">
                         <img
                           src={Paleta}
                           alt=""
                           className=""
                           style={{
                             width: "50%",
                             display: "flex",
                             float: "right"
                           }}
                         />
                         <div className="galeriaPROD">
                           <Features gridItems={gallery.blurbs} />
                         </div>
                       </div>
                       <div className="columns artykulyProd">
                         <div className="column is-12 has-text-centered">
                             
                         </div>
                       </div>
                       <div className="column is-12 wpisy">
                         <h3 className="has-text-weight-semibold is-size-2">
                           Najnowsze wpisy:
                         </h3>
                         <BlogRoll />
                         <div className="column is-12 has-text-centered">
                           <Link className="btn" to="/blog">
                             Czytaj więcej
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
         </div>
       );

ProduktyPageCopy3Template.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const ProduktyPageCopy3 = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ProduktyPageCopy3Template
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

ProduktyPageCopy3.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProduktyPageCopy3

export const pageQuery = graphql`
         query ProduktyPageCopy3Template {
           markdownRemark(
             frontmatter: { templateKey: { eq: "produkty-page copy 3" } }
           ) {
             frontmatter {
               heading
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
